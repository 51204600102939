import { formatDate, FORMATS } from '../../../../utils/date'
import { getChannel } from '../utils'
import CancelOrder from '../actions/cancelorder/CancelOrder'
import OrderStatus from '../orderdetails/OrderStatus'

const ByOrder = ({ order }) => {
  const { orderDate, orderId, channel, orderStatus } = order
  return (
    <div className="cb-card cb-card-event cb-no-padding-left cb-no-padding-right cb-margin-bottom-48">
      <div className="display-flex justify-content-between align-items-start cb-padding-left-24 cb-padding-right-24">
        <div>
          <div className="cb-gray1-color cb-font-size-small cb-roboto-bold cb-margin-bottom-8">
            {formatDate(orderDate, FORMATS.mediumdate)}
          </div>
          <h2 className="cb-h5 cb-margin-bottom-16">{getChannel(channel)}</h2>
        </div>
        <div>
          <CancelOrder orderId={orderId} orderStatus={orderStatus} />
        </div>
      </div>
      <OrderStatus order={order} />
    </div>
  )
}

ByOrder.propTypes = {
  order: PropTypes.object.isRequired,
}

export default ByOrder
