import { ErrorNotification, Modal } from '@cb/apricot-react'
import { useEffect } from 'react'
import { MUTATION_CANCEL_ORDER } from '../../../../../services/graphql/mutations/mutations'
import { useCustomMutation } from '../../../../../services/graphql/utils'
import { canModifyOrder, getTrackingUserId } from '../../../../../utils/auth'
import Button from '../../../../_common/button/Button'
import { ORDER_STATUS_CANCELED, ORDER_STATUS_PLACED } from '../../utils'

export const updateCancelOrder = (existing, orderId) => {
  const { orders = [] } = existing
  const updatedOrders = [...orders]
  const canceledOrderIndex = updatedOrders.findIndex(o => o.orderId === orderId)
  const canceledOrder = updatedOrders[canceledOrderIndex]
  updatedOrders[canceledOrderIndex] = {
    ...canceledOrder,
    orderStatus: ORDER_STATUS_CANCELED,
    dis: canceledOrder.dis.map(d => ({ ...d, orderStatus: ORDER_STATUS_CANCELED })),
  }
  return {
    ...existing,
    orders: updatedOrders,
  }
}

const CancelOrder = ({ orderId, orderStatus }) => {
  const [open, setOpen] = useState(false)
  const canBeCanceled = orderStatus === ORDER_STATUS_PLACED
  const isCanceled = orderStatus === ORDER_STATUS_CANCELED
  const [renderModal, setRenderModal] = useState(canBeCanceled || isCanceled)
  const [cancel, { data, loading, error }] = useCustomMutation(MUTATION_CANCEL_ORDER, {
    update(cache) {
      cache.modify({
        fields: {
          getOrdersAndSendscores(existing = {}) {
            return updateCancelOrder(existing, orderId)
          },
        },
      })
    },
  })

  useEffect(() => {
    if (data) setOpen(false)
  }, [data])

  // after clicking cancel, we need to keep modal in the dom until it is fully closed, then we can remove it
  return canModifyOrder() && orderId ? (
    <>
      {canBeCanceled ? (
        <Button
          title="Cancel Order"
          ariaLabel={`Cancel Order ${orderId}`}
          className="cb-btn-yellow"
          onClick={() => setOpen(true)}
          disabled={open}
        />
      ) : null}
      {renderModal ? (
        <Modal
          open={open}
          onClose={() => {
            setOpen(false)
            setRenderModal(canBeCanceled)
          }}
          title="Cancel Order"
          footer={
            <div className="cb-xs-align-center">
              <Button naked onClick={() => setOpen(false)} disabled={loading}>
                No, Don't Cancel Order
              </Button>
              <Button primary loading={loading} onClick={() => cancel({ orderId, userId: getTrackingUserId() })}>
                Yes, Cancel Order
              </Button>
            </div>
          }
        >
          Are you sure you want to cancel order <strong>{orderId}</strong>?
          {error ? <ErrorNotification title="Error Canceling Order">{error}</ErrorNotification> : null}
        </Modal>
      ) : null}
    </>
  ) : null
}

CancelOrder.propTypes = {
  orderId: PropTypes.number,
  orderStatus: PropTypes.string.isRequired,
}

export default CancelOrder
